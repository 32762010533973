`use strict`;

import Xhr from '../libs/xhr';

class Cart {
    constructor() {
        window.selectors = window.selectors || {};

        window.selectors.addToCartBtn = `.js-add-to-cart-btn`;
        window.selectors.changeQuantityBtn = `.js-change-quantity-from-cart`;
        window.selectors.useCashback = `.js-use-cashback`;
        window.selectors.wrapperWithCashback = `.js-summary-with-cashback`;
        window.selectors.orderSummaryTotalPriceText = `.js-order-summary-total-price`;
        window.selectors.viewCartButton = `.js-view-cart`;
        window.selectors.closeCartButton = `.js-close-cart`;
        window.selectors.productsDetailsCart = `.js-products-details-cart`;
        window.selectors.deliveryTypeBtn = `.js-delivery-type`;
        window.selectors.deliveryLocation = `.js-delivery-location`;
        window.selectors.deliveryLocationShop = `.js-palas-shop`;

        this.initAddToCartListener();
        this.initChangeProductQuantityListener();
        this.initUseCashbackListener();
        this.initViewCartButton();
        this.initCloseCartButton();
        this.productsDetailsCart();
        this.showDeliveryTypeClickListener();
    }

    productsDetailsCart() {
        $('body').on('click', window.selectors.productsDetailsCart, function(e) {
            let clientComments = $('textarea.js-client-comments').val();
            let deliveryType = $('input[name="delivery_type"]:checked').val();
            let deliveryLocation = $('.js-delivery-location').val();
            let deliveryLocationShop = $('.js-palas-shop').val();
            let palasLocationExist = false;
            let deliveryTypeExist = false;

            let deliveryTypeOptions = {
                palas: 'Palas Mall',
                food_court: 'Food Court',
                moldova_mall: 'Moldova Mall',
            };

            $('.js-delivery-type-err').text('');
            $('.js-palas-shop-err').text('');
            $('.js-palas-location-err').text('');

            Object.keys(palasDeliveryLocations).forEach(function(key) {
                if (key === deliveryLocation) {
                    palasLocationExist = true;
                }
            });

            if (deliveryType === 'palas' && palasLocationExist === false) {
                $('.js-palas-location-err').text('Alege o locatie!');
                scrollToSelectDelivery();
                return;
            }

            Object.keys(deliveryTypeOptions).forEach(function(key) {
                if (key === deliveryType) {
                    deliveryTypeExist = true;
                }
            });

            if (deliveryType === 'palas' && deliveryLocation === 'shop' && deliveryLocationShop.length < 1) {
                $('.js-palas-shop-err').text('Alge detalii livrare!');
                scrollToSelectDelivery();
                return;
            }

            if (deliveryTypeExist === false) {
                $('.js-delivery-type-err').text('Alege tipul livrarii!');
                scrollToSelectDelivery();

                return;
            }

            return new Promise((resolve, reject) => {
                let options = {
                    url: window.Laravel.addToCartDetails,
                    data: {
                        clientComments: clientComments,
                    },

                    success: function(data) {
                        if (data.status === 'OK') {
                            window.location.href = window.Laravel.orderDetailsURL;
                        } else if (data.status === 'ERROR') {
                            reject(data.message);
                            return;
                        }
                    },

                    error: function(xhr) {
                        reject('An error has occurred on updating the cart');
                    },
                };

                let xhr = new Xhr();
                xhr.setOptions(options);
                xhr.execute();
            });
        });
    }

    initAddToCartListener() {
        $('body').on('click', window.selectors.addToCartBtn, function(e) {
            e.preventDefault();
            let productID = $(this).data('product');
            let quantity = 1;
            let operation = 'add';

            // quantity from input text
            if ($(`.js-add-to-cart-quantity`).length) {
                let qty = parseInt($('.js-add-to-cart-quantity').val());
                if (qty > 0 && qty < 5000) {
                    quantity = qty;
                } else {
                    alert('Cantitatea nu poate fi procesata.');
                    return false;
                }
            }

            // ajax
            // 1. update number of products from cosul meu
            // 2. show modal with a small order summary and buttons like: Vezi Cos, Continua cumparaturile

            return new Promise((resolve, reject) => {
                let options = {
                    url: window.Laravel.addToCartUrl,

                    data: {
                        productID: productID,
                        quantity: quantity,
                        operation: operation,
                    },

                    success: function(data) {
                        if (data.status === 'OK') {
                            // open modal dialog
                            $('#cart-info-modal').dialog({
                                modal: true,
                                resizable: false,
                                draggable: false,
                            });
                            // populate modal content with new data
                            $('.js-cart-content').html(data.message);
                            // update Cosul meu quantity
                            $('.js-quantity-text')
                                .css('display', '')
                                .html(data.total_cart_quantity);
                        } else if (data.status === 'ERROR') {
                            reject(data.message);
                            return;
                        } else if (data.status === 'NOT_OPEN') {
                            $('#cart-info-modal').dialog({
                                modal: true,
                                resizable: false,
                                draggable: false,
                            });
                            // populate modal content with new data
                            $('.js-cart-content').html(data.message);
                            $('.js-cart-modal-title').text('Magazinul este inchis!');
                            $('.js-close-cart').text('Inchide');
                            $('.js-view-cart').hide();
                        }
                    },
                    fail: function() {
                        console.log('fail');
                    },

                    error: function(xhr) {
                        reject(xhr.responseJson);
                    },
                };

                let xhr = new Xhr();
                xhr.setOptions(options);
                xhr.execute();
            });
        });
    }

    initChangeProductQuantityListener() {
        $('body').on('click', window.selectors.changeQuantityBtn, function(e) {
            let productID = $(this).data('product-id');
            let quantity = $(this).data('quantity');
            let operation = $(this).data('operation');

            return new Promise((resolve, reject) => {
                let options = {
                    url: window.Laravel.addToCartUrl,

                    data: {
                        productID: productID,
                        quantity: quantity,
                        operation: operation,
                    },

                    success: function(data) {
                        if (data.status === 'OK') {
                            window.location.href = window.Laravel.seeCart;
                        } else if (data.status === 'ERROR') {
                            reject(data.message);
                            return;
                        }
                    },

                    error: function(xhr) {
                        reject('An error has occurred on updating the cart');
                    },
                };

                let xhr = new Xhr();
                xhr.setOptions(options);
                xhr.execute();
            });
        });
    }

    initUseCashbackListener() {
        $('body').on('click', window.selectors.useCashback, function() {
            let useCashback = $(this).is(':checked') ? 1 : 0;

            return new Promise((resolve, reject) => {
                let options = {
                    url: window.Laravel.handleSessionsURL,

                    data: {
                        mode: 'put',
                        useCashback: useCashback,
                    },

                    success: function(data) {
                        if (data.status === 'OK') {
                            window.location.href = window.Laravel.seeCart;
                        }
                    },

                    error: function(xhr) {
                        reject('An error has occurred');
                    },
                };

                let xhr = new Xhr();
                xhr.setOptions(options);
                xhr.execute();
            });
        });
    }

    initViewCartButton() {
        $('body').on('click', window.selectors.viewCartButton, function(e) {
            window.location.href = window.Laravel.seeCart;
        });
    }

    initCloseCartButton() {
        $('body').on('click', window.selectors.closeCartButton, function(e) {
            $('#cart-info-modal').dialog('close');
        });
    }

    showDeliveryTypeClickListener() {
        let deliveryCost = $('.js-delivery-cost');
        let cartDeliveryCost = $('.js-cart-delivery-cost');
        let palasDeliveryLocation = '';
        let palasDeliveryLocationShop = '';
        let deliveryAmount = 0;
        let deliveryType = '';

        $('.js-delivery-location').on('change', function() {
            palasDeliveryLocation = $(this).val();
        });
        palasDeliveryLocationShop = $('.js-palas-shop').val();

        $(window.selectors.deliveryTypeBtn).on('click', function() {
            $('.js-delivery-cost-info').show();
            cartDeliveryCost.show();
            if ($(this).val() === 'palas') {
                deliveryAmount = orderDeliveryCost;
                deliveryType = 'palas';

                $('.js-palas-locations').show();
            }

          if ($(this).val() === 'moldova_mall') {
            deliveryAmount = orderDeliveryCost;
            deliveryType = 'moldova_mall';

            $('.js-palas-locations').hide();
          }

          if ($(this).val() === 'food_court') {
                deliveryAmount = 0;
                deliveryType = 'food_court';
                $('.js-palas-locations').hide();
            }
            deliveryCost.text(deliveryAmount + ' RON');
            updateCartDetails(deliveryType, palasDeliveryLocation, palasDeliveryLocationShop);
        });

        $(window.selectors.deliveryLocation).on('change', function() {
            if ($(this).val() === 'shop') {
                $('.js-delivery-shop-location').show();
            } else {
                $('.js-delivery-shop-location').hide();
            }
            deliveryType = 'palas';
            palasDeliveryLocation = $(this).val();
            palasDeliveryLocationShop = $('.js-palas-shop').val();
            updateCartDetails(deliveryType, palasDeliveryLocation, palasDeliveryLocationShop);
        });

        $(window.selectors.deliveryLocationShop).on('keyup', function() {
            deliveryType = 'palas';
            palasDeliveryLocation = 'shop';
            palasDeliveryLocationShop = $(this).val();
            updateCartDetails(deliveryType, palasDeliveryLocation, palasDeliveryLocationShop);
        });
    }
}

function updateCartDetails(deliveryType, palasDeliveryLocation, palasDeliveryLocationShop) {
    return new Promise((resolve, reject) => {
        let options = {
            url: window.Laravel.addToCartDetails,
            data: {
                deliveryType: deliveryType,
                deliveryLocation: palasDeliveryLocation,
                deliveryLocationShop: palasDeliveryLocationShop,
            },

            success: function(data) {
                if (data.status === 'OK') {
                    if (data.cartTotal) {
                        $('.js-order-summary-total-price').text(data.cartTotal + ' RON');
                    }
                } else if (data.status === 'ERROR') {
                    reject(data.message);
                    return;
                }
            },

            error: function(xhr) {
                reject('An error has occurred on updating the cart');
            },
        };

        let xhr = new Xhr();
        xhr.setOptions(options);
        xhr.execute();
    });
}

function scrollToSelectDelivery() {
    let hash = $('#js-select-delivery');
    $('html, body').animate({
        scrollTop: $(hash).offset().top
    }, 800);
}

new Cart();
